<template>
  <b-modal
    id="popup-common-mission"
    ref="popupCommonMission"
    centered="centered"
    :title="(mission.id ? 'Modifier' : 'Ajouter') + ` une tâche`"
    @ok="create"
    no-close-on-backdrop
  >
    <div v-if="isLoadingMission || isCreatingMission || isLoadingCommonMissionsList || isLoadingDevicesList || isLoadingMissionTypesList">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formMission">
      <b-row v-if="!commonMission">
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Tâche courante" label-for="commonMission" style="flex: 1">
            <v-select id="commonMission" :reduce="(type) => type.id" v-model="mission.commonMissionId" :options="commonMissionsList" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!commonMission">
        <vs-divider color="#0096C7">Créez votre tâche</vs-divider>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider #default="{ errors }" name="Libellé" rules="required">
              <b-form-input id="label" v-model="mission.label" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Type de tâche *" label-for="missionType" style="flex: 1">
            <validation-provider #default="{ errors }" name="missionType" rules="required">
              <v-select
                id="missionType"
                :reduce="(type) => type.id"
                v-model="mission.missionTypeId"
                :options="missionTypeFiltered"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button class="float-left" v-if="!archive" variant="outline-danger" @click="archiveMissionLocal(mission.id, mission.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button class="float-left" v-else variant="outline-danger" @click="deleteMissionLocal(mission.id, mission.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{ mission.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      commonMission: false,
      mission: {
        commonMissionId: null,
        deviceId: null,
        missionTypeId: null,
        label: "",
        isCommon: false,
      },
      archive: false,
    };
  },
  created() {
    this.fetchMissionTypesList();
  },
  methods: {
    popupCreateMissionFromStepEvent(id) {
      this.commonMission = false;
      this.fetchCommonMissionsList();
      if (id > 0) {
        // Si il y a un id, alors c'est une édition
        this.fetchMission(id).then((res) => {
          this.mission.id = res.id;
          this.mission.label = res.label;
          this.mission.missionTypeId = res.missionType.id;
        });
      } else this.initializeForm();
    },
    popupCreateCommonMissionEvent(id) {
      this.commonMission = true;
      if (id) {
        this.fetchMission(id).then((res) => {
          this.mission.id = res.id;
          this.mission.label = res.label;
          this.mission.missionTypeId = res.missionType.id;
        });
      } else this.initializeForm();
    },
    popupCreateCommonMissionArchivedEvent(id) {
      this.archive = true;
      this.commonMission = true;
      if (id) {
        this.fetchMissionArchived(id).then((res) => {
          this.mission.id = res.id;
          this.mission.label = res.label;
          this.mission.missionTypeId = res.missionType.id;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.mission = {
        commonMissionId: null,
        deviceId: null,
        missionTypeId: null,
        label: "",
        isCommon: false,
      };
    },
    createDevice(id) {
      this.$bvModal.show("popup-device");
      this.$refs["popupDevice"].popupCreateDeviceEvent(id);
    },
    deleteMissionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous ne pourrez plus restaurer cette tâche en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir supprimer la tâche ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteMission(id).then(() => {
              this.$emit("deleteMissionInSteps", this.stepIndex, id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-common-mission");
            });
          }
        });
    },
    archiveMissionLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez restaurer cette tâche en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir archiver la tâche ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveMission(id).then(() => {
              this.$emit("deleteMissionInSteps", this.stepIndex, id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-common-mission");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formMission.validate().then((success) => {
        if (success) {
          if (this.commonMission) {
            // Si la mission est créée ou midifiée depuis la page des missions communes
            if (!this.mission.id) {
              // Si il n'y a pas d'id, alors c'est une création de mission commune
              this.createMission({ label: this.mission.label, isCommon: true, missionTypeId: this.mission.missionTypeId }).then(
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-common-mission");
                  this.initializeForm();
                })
              );
            } else {
              // Sinon, c'est une édition d'une mission commune existante
              this.updateMission({ label: this.mission.label, isCommon: true, missionTypeId: this.mission.missionTypeId, id: this.mission.id }).then(
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-common-mission");
                  this.initializeForm();
                })
              );
            }
          } else {
            // Sinon la mission est créée ou modifiée depuis la popup des étapes communes
            if (!this.mission.id) {
              // Si il n'y a pas d'id, alors c'est une création de mission commune
              this.createMission({ label: this.mission.label, isCommon: false, missionTypeId: this.mission.missionTypeId }).then((res) => {
                this.$emit("popupMissionClose", { data: res });
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-common-mission");
                  this.initializeForm();
                });
              });
            } else {
              // Sinon, c'est une édition d'une mission commune existante
              this.updateMission({ label: this.mission.label, isCommon: false, missionTypeId: this.mission.missionTypeId, id: this.mission.id }).then((res) => {
                this.$emit("updateMission", { data: res });
                this.$nextTick(() => {
                  this.$bvModal.hide("popup-common-mission");
                  this.initializeForm();
                });
              });
            }
          }
        }
      });
    },
    ...mapActions([
      "createMission",
      "updateMission",
      "deleteMission",
      "archiveMission",
      "fetchMission",
      "fetchCommonMissionsList",
      "fetchMissionTypesList",
      "fetchMissionArchived",
    ]),
  },
  computed: {
    ...mapGetters([
      "isCreatingMission",
      "commonMissionsList",
      "missionTypesList",
      "devicesList",
      "isLoadingMission",
      "isLoadingMissionTypesList",
      "isLoadingDevicesList",
      "isLoadingCommonMissionsList",
    ]),
    missionTypeFiltered() {
      return this.missionTypesList.filter((elem) => elem.id !== 22);
    },
  },
  watch: {
    "mission.commonMissionId": function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        const commonMissionSelected = this.commonMissionsList.find((elem) => elem.id === newValue);
        this.mission.label = commonMissionSelected.label;
        this.mission.missionTypeId = commonMissionSelected.missionType.id;
        this.mission.isCommon = false;
      }
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>
